import { IconProps } from '@tabler/icons-react';
import React from 'react';
import { PropsWithClassName } from '@Components/helper';
import { TextColor } from '@Helpers/types/text';
import { Root } from './Icon.styles';
interface Props extends PropsWithClassName, IconProps {
  icon: (props: IconProps) => React.ReactNode;
  color?: TextColor;
  hoverColor?: TextColor;
  onClick?: () => void;
}
const Icon = (props: Props): React.ReactElement => {
  const {
    icon,
    size,
    color = TextColor.Primary,
    stroke,
    strokeWidth,
    onClick,
    hoverColor
  } = props;
  return <Root as={icon} className={props.className} data-testid={props.dataTestId ? props.dataTestId : 'Icon-root'} size={size} strokeWidth={strokeWidth} $color={color} $hoverColor={hoverColor} $isPointer={!!onClick} stroke={stroke} onClick={onClick} data-sentry-element="Root" data-sentry-component="Icon" data-sentry-source-file="Icon.tsx" />;
};
export { Icon };