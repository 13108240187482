import React from 'react';
import { PropsWithClassName } from '@Components/helper';
import { Root, StyledImage } from './UserImage.styles';
interface Props extends PropsWithClassName {
  userImageSrcModel: UserImageSrcModel;
  userImageSize: UserImageSize;
  width: number;
  height: number;
  onClick?: () => void;
}
export interface UserImageSrcModel {
  small: string;
  medium: string;
  large: string;
}
export enum UserImageSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}
const UserImage = (props: Props): React.ReactElement => {
  const {
    userImageSrcModel,
    onClick,
    width,
    height,
    userImageSize
  } = props;
  const src = userImageSrcModel[userImageSize];
  return <Root onClick={() => {
    if (onClick) {
      onClick();
    }
    return;
  }} className={props.className} data-testid="user-image-root" data-sentry-element="Root" data-sentry-component="UserImage" data-sentry-source-file="UserImage.tsx">
      <StyledImage src={src} width={width} height={height} data-testid="user-image-src" data-sentry-element="StyledImage" data-sentry-source-file="UserImage.tsx" />
    </Root>;
};
export { UserImage };